import React, { useState, useEffect, SetStateAction } from 'react';

type UseDebounceType = <T extends unknown>(initialValue: T, time: number) => [T, T, React.Dispatch<SetStateAction<T>>];

const useDebounce: UseDebounceType = <T extends unknown>(initialValue: T, time: number) => {
    const [value, setValue] = useState<T>(initialValue);
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

    useEffect(() => {
        const debounce = setTimeout(() => setDebouncedValue(value), time);
        return () => clearTimeout(debounce);
    }, [value, time]);

    return [debouncedValue, value, setValue];
};

export default useDebounce;