import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const mainSearchContainer = document.getElementById('search-form') as HTMLElement;
if (mainSearchContainer) {
  const mainSearchRoot = ReactDOM.createRoot(mainSearchContainer);
  
  mainSearchRoot.render(
    <React.StrictMode>
      <App 
        searchAction={mainSearchContainer.dataset.searchaction ?? ''}
        searchPageId={mainSearchContainer.dataset.searchpageid ?? undefined}
        searchSuggestAction={mainSearchContainer.dataset.searchsuggestaction ?? ''}
        placeholderText={mainSearchContainer.dataset.placeholder ?? 'Search'}
        searchType="homepage"
      />
    </React.StrictMode>
  );
}

const bannerSearchContainer = document.getElementById('search-form-banner') as HTMLElement;

if (bannerSearchContainer) {
  const bannerSearchRoot = ReactDOM.createRoot(bannerSearchContainer);

  if (bannerSearchContainer.dataset.deptsearchaction) {
    bannerSearchRoot.render(
      <React.StrictMode>
        <App 
          searchAction={bannerSearchContainer.dataset.searchaction ?? ''}       
          searchPageId={bannerSearchContainer.dataset.searchpageid ?? undefined}
          placeholderText={bannerSearchContainer.dataset.placeholder ?? 'Search'}
          searchSuggestAction={bannerSearchContainer.dataset.searchsuggestaction ?? ''}
          searchType="banner"
          deptSearchAction={bannerSearchContainer.dataset.deptsearchaction ?? undefined}
          deptSearchPageId={bannerSearchContainer.dataset.deptsearchpageid ?? undefined}
          deptName={bannerSearchContainer.dataset.deptname ?? undefined}
          superSearchLabel={bannerSearchContainer.dataset.headerlabel ? bannerSearchContainer.dataset.headerlabel : 'All of Sonomacounty.ca.gov'}
        />
      </React.StrictMode>
    );
  } else {
    bannerSearchRoot.render(
      <React.StrictMode>
        <App 
          searchAction={bannerSearchContainer.dataset.searchaction ?? ''}
          searchPageId={bannerSearchContainer.dataset.searchpageid ?? undefined}
          searchSuggestAction={bannerSearchContainer.dataset.searchsuggestaction ?? ''}
          placeholderText={bannerSearchContainer.dataset.placeholder ?? 'Search'}
          searchType="banner"
        />
      </React.StrictMode>
    );
  }
}

const mobileSearchContainer = document.getElementById('search-form-mobile') as HTMLElement;

if (mobileSearchContainer) {
  const mobileSearchRoot = ReactDOM.createRoot(mobileSearchContainer);

  mobileSearchRoot.render(
    <React.StrictMode>
      <App 
        searchAction={mobileSearchContainer.dataset.searchaction ?? ''}
        searchPageId={mobileSearchContainer.dataset.searchpageid ?? undefined}
        searchSuggestAction={mobileSearchContainer.dataset.searchsuggestaction ?? ''}
        placeholderText={mobileSearchContainer.dataset.placeholder ?? 'Search'}
        searchType="mobile"
      />
    </React.StrictMode>
  );
}